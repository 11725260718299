import React from 'react';
import styled from 'styled-components';

import { mediaSelector } from 'helpers/style-utils';

import { useContentContext } from 'components/shared/Dropdown';
import { Body as DropdownBody } from 'components/shared/Dropdown/styles';
import { useMobileViewContext } from 'components/boards/MobileViewContext';

import ArrowIcon from './ArrowIcon.svg';

const Container = styled.div`
  position: relative;
`;

export const Menu = styled(DropdownBody)`
  width: 100%;
`;

const Handler = styled.div`
  position: relative;

  > svg {
    position: absolute;
    right: 10px;
    top: 11px;

    path[stroke] {
      stroke: ${p => p.theme.currentTheme.text};
    }

    ${mediaSelector.mobile} {
      top: 17px;
    }
  }
`;

const Wrapper = styled.div<{ toTop?: boolean; toLeft?: boolean; width?: number }>`
  position: absolute;
  width: ${p => (p.width ? `${p.width + 6}px` : '100%')};
  display: none;
  padding-${p => (p.toLeft ? 'right' : 'left')}: 6px;
  ${p => (p.toLeft ? 'right' : 'left')}: 100%;
  ${p => (p.toTop ? 'bottom' : 'top')}: -5px;

  ${Container}:hover & {
    display: block;
  }

  ${mediaSelector.mobile} {
    display: block;
    padding: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: unset;
  }
`;

type Props = {
  handler: React.ReactNode;
  expandToUp?: boolean;
  width?: number;
  children: React.ReactNode;
};

const ListItemMenu: React.FC<Props> = ({ handler, expandToUp, width, children }) => {
  const { replaceContent } = useContentContext();
  const { isMobileView } = useMobileViewContext();
  const toLeft = false; // fix me

  return (
    <Container>
      <Handler
        onClick={() => {
          if (isMobileView) {
            replaceContent(<Menu>{children}</Menu>);
          }
        }}
      >
        {handler}
        <ArrowIcon />
      </Handler>
      {!isMobileView && (
        <Wrapper toTop={expandToUp} toLeft={toLeft} width={width}>
          <Menu>{children}</Menu>
        </Wrapper>
      )}
    </Container>
  );
};

export default ListItemMenu;
