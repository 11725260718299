/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import Swiper from 'react-id-swiper';

import { useTranslation, Trans } from 'lib/i18n';
import { mediaQuery, mediaSelector } from 'helpers/style-utils';

import { useImageLoader } from 'client/hooks/useImageLoader';
import { Container } from '../styles';

const HeadingBlockContainer = styled.div`
  padding-top: 43px;
  padding: 43px 0 124px;
  overflow: hidden;

  ${mediaSelector.mobile} {
    display: block;
  }
`;

const Wrapper = styled(Container)`
  display: flex;

  ${mediaSelector.mobile} {
    display: block;
  }
`;

const LeftBlock = styled.div`
  flex-basis: 564px;
  flex-shrink: 0;
  padding-top: 60px;

  ${mediaQuery(900)`
    flex-basis: 60%;
  `}

  ${mediaQuery(750)`
    flex-basis: 60%;
  `}
`;

const RightBlock = styled.div``;

const Title = styled.h5`
  font-size: 2.8rem;
  font-weight: 400;
  letter-spacing: -0.137rem;
  margin: 0 0 26px;
`;

const SubTitle = styled.h1`
  font-size: 12rem;
  line-height: 8.4rem;
  font-weight: 700;
  letter-spacing: -0.5rem;
  margin-bottom: 134px;

  ${p => p.theme.fonts.interTight}

  ${mediaQuery(960)`
    font-size: 11rem;
    line-height: 8rem;
  `}

  ${mediaQuery(900)`
    font-size: 10rem;
    line-height: 8rem;
  `}

  ${mediaQuery(850)`
    font-size: 8rem;
    line-height: 6rem;
    letter-spacing: -0.4rem;
  `}

  ${mediaQuery(650)`
    font-size: 7rem;
    line-height: 5rem;
    letter-spacing: -0.3rem;
  `}

  ${mediaSelector.mobile} {
    margin-bottom: 74px;
  }
`;

const Description = styled.p`
  font-size: 1.8rem;
  letter-spacing: -0.091rem;
  color: ${p => p.theme.currentTheme.notes};
  margin-top: -21px;
  
  ${mediaQuery(900)`
    font-size: 1.8rem;
  `}

  ${mediaQuery(850)`
    font-size: 1.7rem;
  `}
  
  ${mediaQuery(800)`
    font-size: 1.7rem;
  `}

  ${mediaQuery(750)`
    font-size: 1.7rem;
    width: 50%;
  `}

  ${mediaSelector.mobile} {
    margin-top: 30px;
    width: 100%;
  }
`;

const Screenshot = styled.div`
  box-shadow: 0px 2px 6px ${p => p.theme.colors.transparentGray},
    0px 1px 2px ${p => p.theme.colors.transparentGray};
  border-radius: 8px;
  max-width: 960px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;

  .swiper-container {
    height: 504px;

    ${mediaSelector.mobile} {
      height: 100%;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  img,
  video {
    display: block;
  }
`;

const FEATURES = [
  'recipes',
  'movies',
  'roadmap',
  'teamwork',
  'novel',
  'fridge',
  'pokemon',
  'simple',
  'wanna',
];

const PATHS = FEATURES.map(key => `/static/images/features/${key}-feature.png`);

const HeadingBlock = () => {
  const { t } = useTranslation('about');
  const { images, isLoading } = useImageLoader(PATHS);

  return (
    <HeadingBlockContainer>
      <Wrapper>
        <LeftBlock>
          <Title>{t('headingBlock.title')}</Title>
          <SubTitle>
            <Trans i18nKey="headingBlock.subTitle" t={t} />
          </SubTitle>
        </LeftBlock>
        <RightBlock>
          <Screenshot>
            {isLoading ? (
              <img src={PATHS[0]} width="960" height="504" alt="" />
            ) : (
              <Swiper
                effect="fade"
                speed={200}
                loop={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
              >
                {images.map((image, i) => (
                  <img
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    src={image.path}
                    width="960"
                    height="504"
                    alt=""
                  />
                ))}
              </Swiper>
            )}
          </Screenshot>
        </RightBlock>
      </Wrapper>
      <Container>
        <Description>{t('headingBlock.description')}</Description>
      </Container>
    </HeadingBlockContainer>
  );
};

export default HeadingBlock;
