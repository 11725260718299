import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { useTranslation, Trans } from 'lib/i18n';

import { mediaQuery } from 'client/helpers/style-utils';
import { staticUrl, contextUrl } from '../lib/utils';

import { TitleText, Container } from '../styles';
import Feature from './Feature';

const FeaturesContainer = styled.div`
  margin-top: 48px;
`;

const StyledTitleText = styled(TitleText)`
  margin-bottom: 55px;

  em {
    font-style: normal;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 5px;
      right: -5px;
      left: 0px;
      height: 5px;
      z-index: -1;
      border-radius: 5px;
      background: ${p => p.theme.colors.dodgerBlue};
    }
  }
`;

const SubTitleText = styled(TitleText)`
  a {
    font-style: normal;
    color: ${p => p.theme.colors.dodgerBlue};
  }
`;

const ExamplesTitle = styled.h2`
  font-size: 10rem;
  line-height: 10rem;
  letter-spacing: -0.04rem;
  margin: 65px 0 60px;

  ${mediaQuery(850)`
    font-size: 8rem;
    line-height: 6rem;
    letter-spacing: -0.4rem;
  `}

  ${mediaQuery(650)`
    font-size: 7rem;
    line-height: 5rem;
    letter-spacing: -0.3rem;
  `}
`;

const FeaturesWrapper = styled.div``;

const SecondaryFeatures = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;

  ${mediaQuery(850)`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${mediaQuery(600)`
    grid-template-columns: repeat(1, 1fr);
  `}
`;

const SecondaryFeature = styled.div`
  font-size: 4.8rem;
  line-height: 4.8rem;
  letter-spacing: -0.3rem;
  padding-bottom: 30px;
`;

const SecondaryFeatureNote = styled.div`
  font-size: 2.8rem;
  line-height: 3.3rem;
  text-align: right;
`;

const FEATURES_LOCALE_KEYS = ['movies', 'roadmap', 'recipes', 'teamwork', 'novel'];

export default function FeaturesBlock() {
  const { t } = useTranslation('about');

  return (
    <FeaturesContainer>
      <Container>
        <StyledTitleText>
          <Trans i18nKey="featuresBlock.title" t={t} />
        </StyledTitleText>
        <SubTitleText>
          <Trans
            i18nKey="featuresBlock.subTitle"
            t={t}
            components={[<a href="https://en.wikipedia.org/wiki/Mind_map" target="_blanc" />]}
          />
        </SubTitleText>
        <ExamplesTitle>{t('featuresBlock.examplesTitle')}</ExamplesTitle>
      </Container>
      <FeaturesWrapper>
        {FEATURES_LOCALE_KEYS.map(key => (
          <Feature
            key={key}
            title={`features.${key}.title`}
            text={`features.${key}.text`}
            screenshot={
              <img
                src={`/static/images/features/${key}-feature.png`}
                width="960"
                height="504"
                alt={`features.${key}.title`}
              />
            }
          />
        ))}
      </FeaturesWrapper>
      <Container>
        <SecondaryFeatures>
          {(t('secondaryFeatures') as string[]).map((feature, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <SecondaryFeature key={i}>{feature}</SecondaryFeature>
          ))}
        </SecondaryFeatures>
        <SecondaryFeatureNote>{t('continue')}</SecondaryFeatureNote>
      </Container>
    </FeaturesContainer>
  );
}
