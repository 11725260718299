import { useState, useEffect } from 'react';

type LoadedImage = {
  path: string;
  element: HTMLImageElement;
  width: number;
  height: number;
};

export const useImageLoader = (paths: string[]) => {
  const [images, setImages] = useState<LoadedImage[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!Array.isArray(paths) || paths.length === 0) {
      setIsLoading(false);
      return;
    }

    const loadImage = (path: string) => {
      return new Promise<LoadedImage>(resolve => {
        const img = new Image();

        img.onload = () => {
          resolve({
            path,
            element: img,
            width: img.width,
            height: img.height,
          });
        };
        img.src = path;
      });
    };

    setIsLoading(true);
    setImages([]);

    Promise.all(paths.map(loadImage))
      .then(images => {
        setImages(images);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [paths]);

  return {
    images,
    isLoading,
  };
};
